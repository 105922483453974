import React from "react";
import {
  Box,
  Container,
  Typography,
  Link,
  IconButton,
  Divider,
} from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";

import vector from "../assets/staticPages/vector.png";
const useStyles = makeStyles((theme) => ({
  footer: {
    fontSize: "17px",
    fontWeight:500,
    fontFamily: "Roboto, Helvetica, sans-serif",
    color: "#1E0E62",
    backgroundColor: "#FFFEFE",
    "&.MuiContainer-root": {
      maxWidth: 1821,//1440,
        [theme.breakpoints.up("md")]:{
      paddingLeft:"76px",
      paddingRight:"76px"}
    },
  },
  logo: {
    textAlign: "left",
    height: "53px",
    width: "925px",
    [theme.breakpoints.down("md")]: { width: "550px", height: "46px" },
    [theme.breakpoints.down("xs")]: { width: "300px", height: "23px" },
  },
  link: { textDecoration: "none", marginRight: 3 },
  socialIcons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: { padding: 0 },
  },
  // footerText: { textAlign: "left", mt: 2 },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box component="footer" sx={{ bgcolor: "White", pb: 3, pt: 1 }}>
      <img src={vector} alt="logo" className={classes.logo} />
      <Container className={classes.footer}>
        <Box
          pb={1}
          sx={{
            display: { xs: "block", md: "flex" },
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ textAlign: "left", fontSize: 24 }}>Logo</Typography>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              paddingRight: 5,
              flexGrow: 1,
            }}
          >
            <Link
              href="/003MVy9Q"
              color="inherit"
              sx={{ textDecoration: "none", marginRight: 3 }}
            >
              About
            </Link>
            <Link
              href="/004MVy9Q"
              color="inherit"
              sx={{ textDecoration: "none", marginRight: 3 }}
            >
              Differentiation
            </Link>
            <Link
              href="/005MVy9Q"
              color="inherit"
              sx={{ textDecoration: "none", marginRight: 3 }}
            >
              Services
            </Link>
            <Link
              href="/006MVy9Q"
              color="inherit"
              sx={{ textDecoration: "none", marginRight: 3 }}
            >
              Product Agnostic
            </Link>
            <Link
              href="/007MVy9Q"
              color="inherit"
              sx={{ textDecoration: "none", marginRight: 3 }}
            >
              DAM Classes
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mt: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{ textAlign: "left", width: 400, fontSize: { xs: 8, md: 16 } }}
          >
            © 2025 DAM Journey LLC
            <br />
            All rights reserved
          </Typography>

        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
// ----- After
// All rights reserved
// </Typography>
// <Box className={classes.socialIcons}>
//   <IconButton
//     href="https://twitter.com"
//     target="_blank"
//     rel="noopener"
//     sx={{ color: "#1E0E62" }}
//   >
//     <TwitterIcon />
//   </IconButton>
//   <IconButton
//     href="https://facebook.com"
//     target="_blank"
//     rel="noopener"
//     sx={{ color: "#1E0E62" }}
//   >
//     <FacebookIcon />
//   </IconButton>
//   <IconButton
//     href="https://plus.google.com"
//     target="_blank"
//     rel="noopener"
//     sx={{ color: "#1E0E62" }}
//   >
//     <GoogleIcon />
//   </IconButton>
// </Box>
