//------------------------- Home Page --------------------------
//Header
export const header = `We take care, y'hear?`;

// Header's text
export const headerText = `Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.`;

// We are independent
export const independentText = `We are a vendor-neutral, vendor-agnostic, product-neutral, product-agnostic practice.`;

// We are a practice
export const practiceText = `We draw a parallel with doctors, lawyers, and craft persons in terms of their professions. What they have in common is they have a practice. We also operate as a practice in the area of DAM.`;

// Immersive Experience
export const immersiveText = `We opt in for an immersive experience. This means that we work the details with the DAM practitioners in your organization in a real-life and real-time learning journey. We accomplish this by executing our proprietary DAM playbook with you and your team members  as active contributors. This also means no webinars and certainly certification classes.`;

// Data Services
export const servicesText = `We get our fingers dirty in the data mapping trenches by migrating your assets to the DAM system. We design the metadata structure and we build the org-wide taxonomy from the ground up.`;

// DAM Journey
export const dAMJourneyText = `We walk with you through your Asset Lifecycle Management journey.
The destinations during this journey are:
Create --> Manage --> Distribute --> Repurpose -->Archive
Create : Design, capture, and ingest
Manage : Review, automate, and approve
Distribute: dynamic delivery, and share
Repurpose : Search, find, collect, and reuse
Archive : expire, and preserve`;

// Our History
export const historyData = {
  2022: `Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.`,
  2023: `Another significant event happened this year. You can add more details here.`,
  2024: `Yet another notable occurrence. Feel free to include whatever text you need.`,
};

// Success Stories
export const cards = [
  {
    // More Dialog Title
    title: `Startup Framework`,
    // Short text that will be shown in the card
    shortText: `Startup Framework gives you complete freedom over your creative process.`,
    // Long text that will be shown in the more dialog
    longText: `Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.`,
    // Name of the company
    companyName: `Company Name`,
  },
  {
    title: `Startup Framework`,
    shortText: `Startup Framework gives you complete freedom over your creative process.`,
    longText: `Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.`,
    companyName: `Company Name`,
  },
  {
    title: `Startup Framework`,
    shortText: `Startup Framework gives you complete freedom over your creative process.`,
    longText: `Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.`,
    companyName: `Company Name`,
  },
];

//DAM Questionnaire
export const questionnaireText = `Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.`;

//------------------------- About Page --------------------------
// Company Name
export const companyName = `Company Name`;

// Sample Text About DAM Services
export const firstHeader = `Sample Text About DAM Services - About`;

// Title 1
export const title_1 = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 1
export const text_1 = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Title 2
export const title_2 = `Key Features of Our DAM Services:`;

// Text 2 items ( it shows as bullets )
export const text_2_items = `Centralized Asset Repository: Consolidate all your digital assets into one easy-to-navigate platform, ensuring consistency and control.
Advanced Search & Metadata Management: Quickly locate files with powerful search functionality and customizable metadata tagging.
Collaboration Made Simple: Facilitate teamwork with tools for sharing, version control, and user permissions.
Secure Access & Compliance: Protect your assets with robust security features, access controls, and compliance with industry regulations.
Integrations & Scalability: Seamlessly connect with your existing tools and scale the platform as your business grows.`;

//  Text 2
export const text_2 = `Our DAM services are tailored to meet the unique needs of businesses across industries, from marketing and media to e-commerce and education. Let us help you unlock the full potential of your digital content.`;

// Title 3
export const title_3 = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 3
export const text_3 = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Immersive Experience Title
export const box_title = `Immersive Experience`;

// Immersive Experience Text
export const box_text = `We opt in for an immersive experience. This means that we work the details with the DAM practitioners in your organization in a real-life and real-time learning journey. We accomplish this by executing our proprietary DAM playbook with you and your team members  as active contributors. This also means no webinars and certainly certification classes.`;

// Title 4
export const title_4 = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 4
export const text_4 = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

//------------------------- Differentiation Page --------------------------
// Company Name
export const companyName_diff = `Company Name`;

// Sample Text About DAM Services
export const firstHeader_diff = `Sample Text About DAM Services - Differentiation`;

// Title 1
export const title_1_diff = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 1
export const text_1_diff = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Title 2
export const title_2_diff = `Key Features of Our DAM Services:`;

// Text 2 items ( it shows as bullets )
export const text_2_items_diff = `Centralized Asset Repository: Consolidate all your digital assets into one easy-to-navigate platform, ensuring consistency and control.
Advanced Search & Metadata Management: Quickly locate files with powerful search functionality and customizable metadata tagging.
Collaboration Made Simple: Facilitate teamwork with tools for sharing, version control, and user permissions.
Secure Access & Compliance: Protect your assets with robust security features, access controls, and compliance with industry regulations.
Integrations & Scalability: Seamlessly connect with your existing tools and scale the platform as your business grows.`;

//  Text 2
export const text_2_diff = `Our DAM services are tailored to meet the unique needs of businesses across industries, from marketing and media to e-commerce and education. Let us help you unlock the full potential of your digital content.`;

// Title 3
export const title_3_diff = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 3
export const text_3_diff = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Immersive Experience Title
export const box_title_diff = `Immersive Experience`;

// Immersive Experience Text
export const box_text_diff = `We opt in for an immersive experience. This means that we work the details with the DAM practitioners in your organization in a real-life and real-time learning journey. We accomplish this by executing our proprietary DAM playbook with you and your team members  as active contributors. This also means no webinars and certainly certification classes.`;

// Title 4
export const title_4_diff = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 4
export const text_4_diff = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

//------------------------- Services Page --------------------------
// Company Name
export const companyName_srv = `Company Name`;

// Sample Text About DAM Services
export const firstHeader_srv = `Sample Text About DAM Services - Services`;

// Title 1
export const title_1_srv = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 1
export const text_1_srv = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Title 2
export const title_2_srv = `Key Features of Our DAM Services:`;

// Text 2 items ( it shows as bullets )
export const text_2_items_srv = `Centralized Asset Repository: Consolidate all your digital assets into one easy-to-navigate platform, ensuring consistency and control.
Advanced Search & Metadata Management: Quickly locate files with powerful search functionality and customizable metadata tagging.
Collaboration Made Simple: Facilitate teamwork with tools for sharing, version control, and user permissions.
Secure Access & Compliance: Protect your assets with robust security features, access controls, and compliance with industry regulations.
Integrations & Scalability: Seamlessly connect with your existing tools and scale the platform as your business grows.`;

//  Text 2
export const text_2_srv = `Our DAM services are tailored to meet the unique needs of businesses across industries, from marketing and media to e-commerce and education. Let us help you unlock the full potential of your digital content.`;

// Title 3
export const title_3_srv = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 3
export const text_3_srv = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Immersive Experience Title
export const box_title_srv = `Immersive Experience`;

// Immersive Experience Text
export const box_text_srv = `We opt in for an immersive experience. This means that we work the details with the DAM practitioners in your organization in a real-life and real-time learning journey. We accomplish this by executing our proprietary DAM playbook with you and your team members  as active contributors. This also means no webinars and certainly certification classes.`;

// Title 4
export const title_4_srv = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 4
export const text_4_srv = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

//------------------------- Product Agnostic Page --------------------------
// Company Name
export const companyName_pro = `Company Name`;

// Sample Text About DAM Services
export const firstHeader_pro = `Sample Text About DAM Services - Product Agnostic`;

// Title 1
export const title_1_pro = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 1
export const text_1_pro = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Title 2
export const title_2_pro = `Key Features of Our DAM Services:`;

// Text 2 items ( it shows as bullets )
export const text_2_items_pro = `Centralized Asset Repository: Consolidate all your digital assets into one easy-to-navigate platform, ensuring consistency and control.
Advanced Search & Metadata Management: Quickly locate files with powerful search functionality and customizable metadata tagging.
Collaboration Made Simple: Facilitate teamwork with tools for sharing, version control, and user permissions.
Secure Access & Compliance: Protect your assets with robust security features, access controls, and compliance with industry regulations.
Integrations & Scalability: Seamlessly connect with your existing tools and scale the platform as your business grows.`;

//  Text 2
export const text_2_pro = `Our DAM services are tailored to meet the unique needs of businesses across industries, from marketing and media to e-commerce and education. Let us help you unlock the full potential of your digital content.`;

// Title 3
export const title_3_pro = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 3
export const text_3_pro = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Immersive Experience Title
export const box_title_pro = `Immersive Experience`;

// Immersive Experience Text
export const box_text_pro = `We opt in for an immersive experience. This means that we work the details with the DAM practitioners in your organization in a real-life and real-time learning journey. We accomplish this by executing our proprietary DAM playbook with you and your team members  as active contributors. This also means no webinars and certainly certification classes.`;

// Title 4
export const title_4_pro = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 4
export const text_4_pro = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

//------------------------- DAM Classes Page --------------------------
// Company Name
export const companyName_dam = `Company Name`;

// Sample Text About DAM Services
export const firstHeader_dam = `Sample Text About DAM Services - DAM Classes`;

// Title 1
export const title_1_dam = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 1
export const text_1_dam = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Title 2
export const title_2_dam = `Key Features of Our DAM Services:`;

// Text 2 items ( it shows as bullets )
export const text_2_items_dam = `Centralized Asset Repository: Consolidate all your digital assets into one easy-to-navigate platform, ensuring consistency and control.
Advanced Search & Metadata Management: Quickly locate files with powerful search functionality and customizable metadata tagging.
Collaboration Made Simple: Facilitate teamwork with tools for sharing, version control, and user permissions.
Secure Access & Compliance: Protect your assets with robust security features, access controls, and compliance with industry regulations.
Integrations & Scalability: Seamlessly connect with your existing tools and scale the platform as your business grows.`;

//  Text 2
export const text_2_dam = `Our DAM services are tailored to meet the unique needs of businesses across industries, from marketing and media to e-commerce and education. Let us help you unlock the full potential of your digital content.`;

// Title 3
export const title_3_dam = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 3
export const text_3_dam = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;

// Immersive Experience Title
export const box_title_dam = `Immersive Experience`;

// Immersive Experience Text
export const box_text_dam = `We opt in for an immersive experience. This means that we work the details with the DAM practitioners in your organization in a real-life and real-time learning journey. We accomplish this by executing our proprietary DAM playbook with you and your team members  as active contributors. This also means no webinars and certainly certification classes.`;

// Title 4
export const title_4_dam = `Streamline Your Digital Asset Management with Our DAM Services`;

// Text 4
export const text_4_dam = `In today’s fast-paced digital landscape, managing your organization’s growing library of digital assets can be a daunting task. Our Digital Asset Management (DAM) services provide an efficient, scalable, and secure solution to store, organize, and distribute your valuable content. Whether you’re handling images, videos, documents, or multimedia files, we ensure your assets are accessible when and where you need them.`;
