import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Grid,
  Divider,
} from "@material-ui/core";
import { Card, CardContent } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { media_url } from "../constants/Data";
import quote from "../assets/staticPages/quote.png";
import damQues from "../assets/staticPages/damQues.png";
import {
  companyName_dam,
  firstHeader_dam,
  title_1_dam,
  title_2_dam,
  title_3_dam,
  title_4_dam,
  text_1_dam,
  text_2_dam,
  text_3_dam,
  text_4_dam,
  text_2_items_dam,
  box_title_dam,
  box_text_dam,
} from "./Texts.js";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    minWidth: "none",
    maxWidth: 1821,
  },

  header: {
    color: "#1E0E62",
    fontSize: 52,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    "@media (max-width: 800px)": {
      fontSize: 22,
    },
  },
  sectionTitle: {
    paddingTop: 20,
    paddingBottom: 10,
    fontSize: 26,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    "@media (max-width: 800px)": {
      fontSize: 16,
    },
  },
  sectionContent: {
    paddingBottom: 30,
    fontSize: 18,
    textAlign: "justify",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    "@media (max-width: 800px)": {
      fontSize: 14,
    },
  },

  section: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      paddingLeft: 10,
      paddingRight: 10,
    },
    "@media (max-width: 800px)": {
      flexDirection: "column",
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  button: {
    width: "200px",
    height: "60px",
    fontSize: "18px",
    textTransform: "none",
    backgroundColor: "#F29F05",
    color: "white",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "#021CA5",
    },
    "@media (max-width: 800px)": {
      width: "106px",
      height: "40px",
      fontSize: "12px",
    },
  },
  company: {
    padding: theme.spacing(4, 0),
    paddingBottom: 10,
    fontSize: 24,
    color: "#F29F05",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    "@media (max-width: 800px)": {
      fontSize: 16,
    },
  },

  immerSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0),
      paddingLeft: 10,
      paddingRight: 10,
    },
    "@media (max-width: 800px)": {
      flexDirection: "column",
      margin: theme.spacing(0, 0),
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  immerBox: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    paddingBottom: "30px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    "@media (max-width: 800px)": {
      margin: theme.spacing(8, 0),
    },
  },
  boxText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "justify",
    textJustify: "inter-word",
    "@media (max-width: 800px)": {
      margin: theme.spacing(2, 0),
    },
  },
  boxTitle: {
    color: "#1E0E62",
    fontSize: 48,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 38,
    },
    "@media (max-width: 885px )": {
      fontSize: 30,
    },
  },
  boxContent: {
    lineHeight: 1.7,
    fontSize: 22,
    textAlign: "justify",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    "@media (max-width: 885px)": {
      margin: theme.spacing(0, 0),
      fontSize: 16,
    },
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const mediaPath = `/${media_url}/`;

  const isMobile = useMediaQuery(`(max-width:800px)`);
  const isTablet = useMediaQuery(`(min-width:801px) and (max-width:1399px)`);
  const isSM = useMediaQuery(`(max-width:950px)`);

  const lines_2 = text_2_items_dam.split("\n");

  return (
    <Container className={classes.root}>
      <Box
        className={classes.section}
        style={{ paddingLeft: isMobile && 0, paddingRight: isMobile && 0 }}
      >
        <Typography className={classes.company}>{companyName_dam}</Typography>
        <Typography className={classes.header}>{firstHeader_dam}</Typography>
        <Typography className={classes.sectionTitle}>{title_1_dam}</Typography>
        <Typography className={classes.sectionContent}>{text_1_dam}</Typography>
        <Typography className={classes.sectionTitle}>{title_2_dam}</Typography>

        <Typography
          className={classes.sectionContent}
          style={{ paddingBottom: 0 }}
        >
          <ul>
            {lines_2.map((line, index) => (
              <li>{line}</li>
            ))}
          </ul>
        </Typography>

        <Typography className={classes.sectionContent}>{text_2_dam}</Typography>
        <Typography className={classes.sectionContent}>
          <b>Get started today!</b> Contact us to learn more about how our{" "}
          <i>DAM services</i> can transform the way you manage your digital
          assets.
        </Typography>
        <Typography className={classes.sectionTitle}>{title_3_dam}</Typography>
        <Typography className={classes.sectionContent}>{text_3_dam}</Typography>
      </Box>
      <Box
        pt={4}
        pb={2}
        className={classes.immerBox}
        sx={{
          backgroundImage: `url(${damQues})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {isMobile ? (
          <>
            <Typography
              variant="h4"
              className={classes.boxTitle}
              style={{ textAlign: "center" }}
            >
              {box_title_dam}
            </Typography>
            <Box
              pt={4}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={mediaPath + "practice.png"} width={200} height={200} />
            </Box>
            <Box pr={0} pt={4} className={classes.sectionText}>
              <Box pt={1} pb={3} pr={3} pl={3}>
                <Typography variant="body1" className={classes.boxContent}>
                  {box_text_dam}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button className={classes.button}>Read More</Button>
              </Box>
            </Box>
          </>
        ) : (
          <Grid container>
            <Grid item xs={1} sm={1} md={1} />
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="h4" className={classes.boxTitle}>
                {box_title_dam}
              </Typography>
              <Box pr={2} className={classes.boxText}>
                <Box pt={3} pb={5}>
                  <Typography
                    variant="body1"
                    className={classes.boxContent}
                    style={{ marginRight: isTablet && "30px" }}
                  >
                    {box_text_dam}
                  </Typography>
                </Box>
                <Box
                  mb={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button className={classes.button}>Read More</Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box style={{ height: 83 }} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={mediaPath + "practice.png"}
                  width={isTablet ? 224 : 314}
                  height={isTablet ? 224 : 314}
                />
              </Box>
            </Grid>
            <Grid item xs={1} sm={3} md={1} />
          </Grid>
        )}
      </Box>
      <Box
        pb={5}
        className={classes.section}
        style={{ paddingLeft: isMobile && 0, paddingRight: isMobile && 0 }}
      >
        <Typography className={classes.sectionTitle}>{title_4_dam}</Typography>
        <Typography className={classes.sectionContent}>{text_4_dam}</Typography>
      </Box>
    </Container>
  );
};

export default HomePage;
